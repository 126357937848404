<script setup lang="ts">
import { useNuxtApp } from '#app'
import { computed } from '#imports'

const props = defineProps<{ disabled?: boolean }>()

const { $protected } = useNuxtApp()
const obscureSlot = computed(() => !(props.disabled || $protected.allClear))
</script>

<template>
  <span
    class="transition-colors"
    :class="{
      'rounded bg-neutral-800': obscureSlot,
    }"
    data-dd-privacy="mask"
  >
    <span
      class="transition-opacity"
      :class="{
        'opacity-0': obscureSlot,
      }"
    >
      <slot />
    </span>
  </span>
</template>
